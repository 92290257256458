import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import GetAppRoundedIcon from '@material-ui/icons/GetAppRounded';
import { saveAs } from 'file-saver';
import React, { useRef, useState } from 'react';
import { useMutation } from 'react-query';
import { Loader } from 'rsuite';
import { useBranch, useBranchId, useSnackbar } from 'utils/customHooks';
import fetch from 'utils/fetch';
import { getDateRange } from 'utils/utils';

export default function Operations() {
  const branchId = useBranchId();
  const branch = useBranch();
  const [notif] = useSnackbar();
  const fileName = useRef(null);
  const progressText = useRef('');

  const [uploadFile] = useMutation(
    ({ url, method, fileData }) =>
      fetch({
        url: url,
        method: method || 'POST',
        accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        headers: {
          'Content-Type': 'application/json, blob',
          'x-wh-id': branchId,
        },
        params: {
          branchId: branchId,
        },
        data: fileData,
      }),
    {
      onSuccess: () => {
        notif('Uploaded successfully', { variant: 'success', autoHideDuration: 1000 });
      },
      onError: () => {
        notif('Failed to upload', { variant: 'error', autoHideDuration: 1000 });
      },
    }
  );

  function prepareAndUploadFile({ url, method }, file) {
    const data = new FormData();
    data.append('file', file);
    uploadFile({ url, method, fileData: data });
  }

  const [downloadSample, { status: downldStatus }] = useMutation(
    ({ url, sessionCode }) =>
      fetch({
        url: url,
        method: 'GET',
        responseType: 'blob',
        wholeResponse: true,
        headers: {
          'Content-Type': 'application/json, blob',
          'x-wh-id': branchId,
        },
        params: {
          branchId: branchId,
          sessionCode: sessionCode,
        },
      }),
    {
      onSuccess: (data) => {
        saveAs(data.data, data.headers.filename ? data.headers.filename : fileName.current);
      },
      onError: () => {
        notif('Download failed', { variant: 'error', autoHideDuration: 1000 });
      },
      onSettled: () => {
        progressText.current = '';
      },
    }
  );
  return (
    <div className="grid md:grid-cols-3 lg:grid-cols-4 gap-3 p-5 bg-background">
      {Object.entries(options).map(([key, value]) => (
        <div className="rounded-lg bg-white p-3" key={key}>
          <p className="font-semibold my-1">{key}</p>
          {value?.map((opt) => {
            return (
              <OpsItem
                key={opt.name}
                item={opt}
                onUploadClicked={prepareAndUploadFile}
                onDownloadClicked={(getUrl, name, sessionCode) => {
                  fileName.current =
                    typeof name === 'string'
                      ? name === 'AddCustomerTemplate'
                        ? 'AddCustomerTemplate'
                        : `${branchId}_${name}`
                      : name(branch?.code);
                  progressText.current = 'Download in progress...';
                  downloadSample({ url: getUrl({ nodeId: branchId }), sessionCode });
                }}
              />
            );
          })}
        </div>
      ))}

      {downldStatus === 'loading' ? (
        <Loader size="md" center backdrop vertical content={progressText.current} />
      ) : null}
    </div>
  );
}

function OpsItem(props) {
  const { item, onUploadClicked, onDownloadClicked } = props;
  const inputFile = useRef(null);
  const [enteredValue, setEnteredValue] = useState('');
  const {
    name,
    hasInput,
    sampleUrl,
    uploadUrl,
    uploadMethod,
    getDownloadUrl,
    fileName,
    upldToolTip,
    dnldToolTip,
  } = item;

  return (
    <div className="flex flex-row items-center flex-grow">
      <div className="flex flex-1">
        {hasInput ? (
          <TextField
            id="sort-session-code-input"
            fullWidth={true}
            label={name}
            type="text"
            variant="outlined"
            size="small"
            placeholder="Enter sort session code"
            autoFocus={false}
            margin="dense"
            value={enteredValue}
            onChange={(event) => setEnteredValue(event.target.value.toUpperCase())}
          />
        ) : (
          <p>{name}</p>
        )}
      </div>
      <div className="flex flex-2 flex-row">
        {sampleUrl ? (
          <Tooltip title={'Sample'}>
            <IconButton href={sampleUrl}>
              <CloudDownloadIcon color="primary" />
            </IconButton>
          </Tooltip>
        ) : null}

        {uploadUrl ? (
          <Tooltip title={upldToolTip || 'Upload'}>
            <div>
              <IconButton
                onClick={() => {
                  inputFile.current.click();
                }}
              >
                <CloudUploadIcon color="primary" />
              </IconButton>
              <input
                type="file"
                id="file"
                ref={inputFile}
                style={{ display: 'none' }}
                onChange={(e) => {
                  onUploadClicked({ url: uploadUrl, method: uploadMethod }, e.target.files[0]);
                  e.target.value = null;
                }}
              />
            </div>
          </Tooltip>
        ) : null}

        {hasInput ? (
          enteredValue.length >= 2 ? (
            <Tooltip title={dnldToolTip}>
              <IconButton onClick={() => onDownloadClicked(getDownloadUrl, fileName, enteredValue)}>
                <GetAppRoundedIcon />
              </IconButton>
            </Tooltip>
          ) : null
        ) : getDownloadUrl ? (
          <Tooltip title={dnldToolTip || 'Download'}>
            <IconButton onClick={() => onDownloadClicked(getDownloadUrl, fileName || name)}>
              <GetAppRoundedIcon />
            </IconButton>
          </Tooltip>
        ) : (
          <div class="w-12"></div>
        )}
      </div>
    </div>
  );
}

function getDefaultDateRange() {
  const [startDate, endDate] = getDateRange(new Date(), 30);
  return `startDate=${startDate}&endDate=${endDate}`;
}

const options = {
  Retailers: [
    {
      name: 'Add customers',
      id: 'add_retailers',
      hasInput: false,
      fileName: 'AddCustomerTemplate',
      dnldToolTip: 'Download customer sample',
      upldToolTip: 'Select file to upload customers',
      getDownloadUrl: () => 'retailer/insert/new/template',
      uploadUrl: 'retailer/insert/new',
    },
    {
      name: 'Update customer details',
      id: 'update_retailer_details',
      hasInput: false,
      fileName: (nodeCode) => `UpdateCustomerTemplate_${nodeCode}`,
      dnldToolTip: 'Download customer details sample',
      upldToolTip: 'Select file to upload customers details',
      getDownloadUrl: () => 'retailer/updates/details/template',
      uploadUrl: 'retailer/updates/details',
    },
  ],
  Sales: [
    {
      name: 'Add salesman',
      id: 'add_salesman',
      hasInput: false,
      fileName: 'salesman_sample',
      dnldToolTip: 'Download salesman sample',
      upldToolTip: 'Select file to upload salesman',
      getDownloadUrl: () => 'user/bulkCreate/template?designation=salesman',
      uploadUrl: 'user/bulkCreate?designation=salesman',
    },
  ],

  'Salesman Targets': [
    {
      name: 'Sales Targets',
      sampleUrl: '/api/salesmanTarget/SALES/create/template',
      getDownloadUrl: ({ nodeId }) =>
        `/salesmanTarget/download?nodeId=${nodeId}&${getDefaultDateRange()}`,
      uploadUrl: '/salesmanTarget/SALES',
    },
    {
      name: 'SKU Targets',
      sampleUrl: '/api/salesmanTarget/SKU/create/template',
      getDownloadUrl: ({ nodeId }) =>
        `/salesmanTarget/download?nodeId=${nodeId}&${getDefaultDateRange()}`,
      uploadUrl: '/salesmanTarget/SKU',
    },
    {
      name: 'Productivity Targets',
      sampleUrl: '/api/salesmanTarget/PRODUCTIVITY/create/template',
      getDownloadUrl: ({ nodeId }) =>
        `/salesmanTarget/download?nodeId=${nodeId}&${getDefaultDateRange()}`,
      uploadUrl: '/salesmanTarget/PRODUCTIVITY',
    },
    {
      name: 'Adherence Targets',
      sampleUrl: '/api/salesmanTarget/ADHERENCE/create/template',
      getDownloadUrl: ({ nodeId }) =>
        `/salesmanTarget/download?nodeId=${nodeId}&${getDefaultDateRange()}`,
      uploadUrl: '/salesmanTarget/ADHERENCE',
    },
    {
      name: 'Update Sales Targets',
      sampleUrl: '/api/salesmanTarget/SALES/update/template',
      uploadUrl: '/salesmanTarget/SALES',
      uploadMethod: 'PUT',
    },
    {
      name: 'Update SKU Targets',
      sampleUrl: '/api/salesmanTarget/SKU/update/template',
      uploadUrl: '/salesmanTarget/SKU',
      uploadMethod: 'PUT',
    },
    {
      name: 'Update Productivity Targets',
      sampleUrl: '/api/salesmanTarget/PRODUCTIVITY/update/template',
      uploadUrl: '/salesmanTarget/PRODUCTIVITY',
      uploadMethod: 'PUT',
    },
    {
      name: 'Update Adherence Targets',
      sampleUrl: '/api/salesmanTarget/ADHERENCE/create/template',
      uploadUrl: '/salesmanTarget/ADHERENCE',
      uploadMethod: 'PUT',
    },
  ],
  Downloads: [
    {
      name: 'Download customers',
      id: 'download_retailers',
      hasInput: false,
      fileName: 'customers_data',
      dnldToolTip: 'Download customers',
      upldToolTip: '',
      getDownloadUrl: () => 'retailer?assignedSalesman=true&mapped=true&output=xlsx',
    },
    {
      name: 'Download users',
      id: 'download_users',
      hasInput: false,
      fileName: 'users_data',
      dnldToolTip: 'Download users',
      upldToolTip: '',
      getDownloadUrl: () => 'user/dump',
    },
  ],
  'Coverage Expansion': [
    {
      name: 'Channel Mapping',
      getDownloadUrl: () => '/coverageExpansion/downloadChannelMapping',
      dnldToolTip: 'Download Existing data',
      uploadUrl: '/coverageExpansion/uploadChannelMapping',
      upldToolTip: 'Upload Changes required',
    },
    {
      name: 'Reason Mapping',
      getDownloadUrl: () => '/coverageExpansion/downloadStatusReasons',
      dnldToolTip: 'Download Existing data',
      uploadUrl: '/coverageExpansion/uploadStatusReasons',
      upldToolTip: 'Upload Changes required',
    },
  ],
};
